import React from 'react';
import { trackEvent } from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { membershipIntakeUrl } from 'utils/helpers';
import { MembershipProductType, PageSectionType } from 'utils/types';

import { DefaultContentSection } from '../../../components/PageSections/DefaultContentSection';

export type MembershipEnrollProps = {
  content: PageSectionType;
  membershipVariant: MembershipProductType;
  loggedIn: boolean;
};

export const MembershipEnrollSection: React.FC<MembershipEnrollProps> = ({
  content,
  membershipVariant,
  loggedIn,
}) => {
  const {
    slug,
    title,
    headline,
    subHeadline,
    subHeadlineDescription,
    description,
    mainCta,
    className,
    backgroundColor,
    embeddedVideoLink,
  } = content;

  // TODO: We should be using labels for these, not control-specific events
  const trackMainCTA = (): void => {
    trackEvent(ANALYTICS.EVENTS.CONTROL_ENROLL_BUTTON_CLICK);
  };

  const handleClick = (): void => {
    trackMainCTA();
    window.location.href = membershipIntakeUrl(membershipVariant.id);
  };

  return (
    <DefaultContentSection
      uid={slug}
      title={title}
      headline={headline}
      subHeadline={subHeadline}
      subHeadlineDescription={
        !loggedIn ? subHeadlineDescription?.subHeadlineDescription : ''
      }
      body={description?.childMarkdownRemark?.html || ''}
      cta={mainCta}
      className={className}
      backgroundColor={backgroundColor}
      videoLink={embeddedVideoLink}
      onCtaClick={handleClick}
    />
  );
};

export default MembershipEnrollSection;
